import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { QueryEntityCustom } from '@memberspot/frontend/shared/data-access/common';
import { School } from '@memberspot/shared/model/school';
import { filter, first, map, Observable } from 'rxjs';

import { hasAcceptedLastestDpa } from '../dpa-check';
import { SchoolsState, SchoolsStore } from './schools.store';

@Injectable({ providedIn: 'root' })
export class SchoolsQuery extends QueryEntityCustom<SchoolsState> {
  constructor(
    protected override store: SchoolsStore,
    protected override routerQuery: RouterQuery,
    private _router: Router,
  ) {
    super(store, routerQuery);
  }

  executeIfDpaAccepted(fn: () => any) {
    this.selectActive()
      .pipe(
        first(),
        filter((s): s is School => !!s),
      )
      .subscribe((school) => {
        if (hasAcceptedLastestDpa(school)) {
          fn();

          return;
        }

        this._router.navigateByUrl(`${school.id}/dpa-not-accepted`);
      });
  }

  selectAllSchools(): Observable<School[] | null> {
    return this.selectAllNullLoading({
      filterBy: (entity) => !entity.expiresAt,
    });
  }

  selectSideNavEnabled() {
    return this.selectActive().pipe(
      map((school) => school?.sideNavEnabled || false),
    );
  }
}
